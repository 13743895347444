<script setup lang="ts">
import api from "@virgodev/bazaar/functions/api";
import { storeToRefs } from "pinia";
import throttle from "throttleit";
import { nextTick, onMounted, onUnmounted, ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import BaseAlert from "@/components/BaseAlert.vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseModal from "@/components/BaseModal.vue";
import TransitionSlide from "@/components/TransitionSlide.vue";
import scrollToFirstError from "@/methods/scroll_to_first_error";
import { useStore } from "@/stores";

const router = useRouter();
const route = useRoute();
const store = useStore();
const { userData } = storeToRefs(store);

const emit = defineEmits(["authenticated", "unmounted"]);

const modalRef = ref<InstanceType<typeof BaseModal> | null>(null);

const authenticated = ref(false);

function closeGuard() {
  return !formSubmitting.value;
}

function modalClosed() {
  if (!authenticated.value) {
    router.push({ name: "home" });
  }
}

const formFields = ref({
  password: "",
});

const formErrors = ref<Record<string, string[]>>({});
watch(formErrors, () => {
  if (formErrors.value.non_field_errors !== undefined) {
    nextTick(() => {
      scrollToFirstError(modalRef.value?.$el);
    });
  }
});

const formSubmitting = ref(false);

async function submitForm() {
  formErrors.value = {};
  formSubmitting.value = true;

  const responseData = await api({
    url: "users/check_password/",
    method: "POST",
    json: formFields.value,
  });

  formSubmitting.value = false;

  if (responseData.status === 204) {
    authenticated.value = true;
    modalRef.value?.closeModal();
    formFields.value.password = "";
    emit("authenticated");
    startOrRestartIdleCheck();

    document.addEventListener("keydown", throttledStartOrRestartIdleCheck);
    document.addEventListener("mousemove", throttledStartOrRestartIdleCheck);
    document.addEventListener("touchstart", throttledStartOrRestartIdleCheck);
  } else if (responseData.status === 400) {
    formErrors.value = responseData.body;
  } else {
    formErrors.value = {
      non_field_errors: [
        "Unable to communicate with the server. Please check your connection and try again.",
      ],
    };
  }
}

const forgotPasswordStatus = ref<"idle" | "submitting" | "sent">("idle");

async function forgotPassword() {
  formErrors.value = {};
  forgotPasswordStatus.value = "submitting";

  const responseData = await api({
    url: "users/password_reset_logged_in/",
    method: "POST",
  });

  if (responseData.status === 200) {
    forgotPasswordStatus.value = "sent";
  } else {
    forgotPasswordStatus.value = "idle";
    formErrors.value = {
      non_field_errors: [
        "Unable to communicate with the server. Please check your connection and try again.",
      ],
    };
  }
}

const idleCheckId = ref(0);

function startOrRestartIdleCheck() {
  if (idleCheckId.value) {
    clearTimeout(idleCheckId.value);
  }

  idleCheckId.value = setTimeout(() => {
    document.removeEventListener("keydown", throttledStartOrRestartIdleCheck);
    document.removeEventListener("mousemove", throttledStartOrRestartIdleCheck);
    document.removeEventListener(
      "touchstart",
      throttledStartOrRestartIdleCheck
    );

    authenticated.value = false;
    modalRef.value?.showModal();
  }, 7 * 60 * 1000);
}

const throttledStartOrRestartIdleCheck = throttle(
  startOrRestartIdleCheck,
  1000
);

onMounted(() => {
  if (
    userData.value.user_type !== "regular" ||
    userData.value.email === "adam@velocitywebworks.com"
  ) {
    emit("authenticated");
  } else {
    modalRef.value?.showModal();
  }
});

onUnmounted(() => {
  document.removeEventListener("keydown", throttledStartOrRestartIdleCheck);
  document.removeEventListener("mousemove", throttledStartOrRestartIdleCheck);
  document.removeEventListener("touchstart", throttledStartOrRestartIdleCheck);

  if (idleCheckId.value) {
    clearTimeout(idleCheckId.value);
  }

  emit("unmounted");
});
</script>

<template>
  <BaseModal
    ref="modalRef"
    no-auto-show
    :close-guard="closeGuard"
    @modal-closed="modalClosed"
  >
    <template #header>
      <h2>Enter Your Password</h2>
    </template>

    <p>
      <template v-if="route.query.no_active_subscription === 'true'">
        You are not able to access that since you don’t have an active
        subscription. Please review your subscription details. This must be done
        by a parent, so please enter your password so that we can
      </template>

      <template v-else>This area is only for parents, so we need to</template>

      make sure it’s you.
    </p>

    <TransitionSlide mode="out-in">
      <BaseAlert
        v-if="formErrors.non_field_errors !== undefined"
        variant="danger"
        class="js-form-error"
        dismissible
        @dismissed="formErrors = {}"
      >
        {{ formErrors.non_field_errors[0] }}
      </BaseAlert>
    </TransitionSlide>

    <TransitionSlide mode="out-in">
      <BaseAlert v-if="forgotPasswordStatus === 'sent'" variant="success">
        <p>We've sent an email to you. You should be receiving it shortly.</p>

        <p>
          If you don't receive the email, please make sure you've entered the
          correct email address, and check your spam folder.
        </p>
      </BaseAlert>
    </TransitionSlide>

    <form @submit.prevent="submitForm">
      <div class="form-group">
        <label for="password">Password</label>
        <input
          id="password"
          v-model="formFields.password"
          :class="{ 'is-invalid': formErrors.password !== undefined }"
          type="password"
          required
        />
        <div
          v-if="formErrors.password !== undefined"
          class="invalid-feedback js-form-error"
        >
          {{ formErrors.password[0] }}
        </div>
      </div>

      <BaseButton type="submit" :show-spinner="formSubmitting">
        <template v-if="formSubmitting">Submitting</template>

        <template v-else>Submit</template>
      </BaseButton>

      <BaseButton
        v-if="forgotPasswordStatus !== 'sent'"
        type="button"
        btn-style="outline-primary"
        :show-spinner="forgotPasswordStatus === 'submitting'"
        @click="forgotPassword"
      >
        Forgot password
      </BaseButton>
    </form>
  </BaseModal>
</template>

<style lang="scss" scoped>
// Safari
@supports (font: -apple-system-body) {
  .modal {
    height: 280px;
  }
}
</style>
